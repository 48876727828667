import type { Bench } from "../server/benches";
import {
  circle,
  Circle,
  divIcon,
  LatLng,
  latLngBounds,
  Layer,
  LocationEvent,
  map as leafletMap,
  marker,
  marker as leafletMarker,
  tileLayer,
} from "leaflet";
import {
  mdiAlert,
  mdiBenchBack,
  mdiBusStop,
  mdiBusStopCovered,
  mdiHelp,
  mdiHome,
  mdiLoading,
  mdiCrosshairsGps,
  mdiTablePicnic,
} from "@mdi/js";
import { MapButton } from "./MapButton";
import { getBounds } from "../server/helpers";
import { SearchBox } from "./SearchBox";
import { ClientInfo } from "../server/types";

const map = leafletMap("mappie", { zoomAnimation: false });

function fitServerBounds() {
  if (clientInfo.config.bounds)
    map.fitBounds(
      latLngBounds([
        [clientInfo.config.bounds.minlat, clientInfo.config.bounds.minlon],
        [clientInfo.config.bounds.maxlat, clientInfo.config.bounds.maxlon],
      ]),
    );
  else map.fitWorld();
}

const response = await fetch("/api/info");
export const clientInfo = (await response.json()) as ClientInfo;
document.getElementById("latest")!.innerText = clientInfo.latest;
tileLayer(clientInfo.config.tileServerUrl, {
  attribution: clientInfo.config.attribution,
}).addTo(map);
const position = localStorage.getItem("position");
if (position) {
  const { center, zoom } = JSON.parse(position) as {
    center: LatLng;
    zoom: number;
  };
  map.setView(center, zoom);
} else fitServerBounds();

const radius: number = 500;
const iconSize: number = 24;
map.addControl(new SearchBox((latLng) => void onMapClick(latLng)));
map.on("click", (event) => {
  void onMapClick(event.latlng);
});
["zoomend", "moveend"].forEach((event) => map.on(event, saveBounds));
new MapButton(fitServerBounds, mdiHome).addTo(map);
new MapButton(locate, mdiCrosshairsGps).addTo(map);

function saveBounds() {
  const center = map.getCenter();
  const zoom = map.getZoom();
  localStorage.setItem(
    "position",
    JSON.stringify({
      center,
      zoom,
    }),
  );
}
function getColor(value: number) {
  const hue = (value * 120).toString(10);
  return ["hsl(", hue, ",100%,50%)"].join("");
}
function getTooltipPath(type: Bench["type"]): string {
  if (type === "bench") return mdiBenchBack;
  if (type === "picnic_table") return mdiTablePicnic;
  if (type === "shelter") return mdiBusStopCovered;
  if (type === "transit_stop") return mdiBusStop;
  return mdiHelp;
}
function getIconSvg(icon: string, color: string): SVGSVGElement {
  const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute(
    "style",
    `width: ${iconSize}px; height: ${iconSize}px; background-color: ${color}; border-radius: 50%;`,
  );
  svg.setAttribute("class", "zitzoekerMarker");
  svg.setAttribute("viewBox", `0 0 ${iconSize} ${iconSize}`);
  const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  path.setAttribute("d", icon);
  svg.appendChild(path);
  return svg;
}
function getTooltipSvg(marker: Bench): SVGSVGElement {
  return getIconSvg(getTooltipPath(marker.type), getColor(marker.certainty));
}
function getText(marker: Bench): string {
  const table = [["marker type", marker.type], ...Object.entries(marker.tags)]
    .map(([key, value]) => `<tr><td>${key}</td><td>${value}</td></tr>`)
    .join("");
  return `<table>${table}</table>`;
}
let leafletMarkers: Layer[] = [];
function drawMarkers(benches: Bench[]) {
  leafletMarkers.forEach((marker) => marker.removeFrom(map));
  leafletMarkers = benches
    .map((marker) => {
      return leafletMarker([marker.lat, marker.lon], {
        icon: divIcon({
          html: getTooltipSvg(marker).outerHTML,
          iconSize: [iconSize, iconSize],
        }),
      }).bindPopup(() => getText(marker));
    })
    .map((marker) => marker.addTo(map));
}
let searchCircle: Circle | null = null;
async function onMapClick(position: LatLng) {
  const { lat, lng } = position;
  const { minlat, maxlat, minlon, maxlon } = getBounds(
    {
      lat,
      lon: lng,
    },
    radius,
  );
  const bounds = latLngBounds([
    [minlat, minlon],
    [maxlat, maxlon],
  ]);
  map.fitBounds(bounds, { animate: false });
  const loadingMarker = marker([lat, lng], {
    icon: divIcon({
      html: getIconSvg(mdiLoading, "#00F").outerHTML,
      className: "loadingMarker",
      iconSize: [iconSize, iconSize],
    }),
  }).addTo(map);
  let data: Bench[] = [];
  try {
    const response = await fetch(
      `/api/benches?radius=${radius}&lat=${lat}&lon=${lng}`,
    );
    data = (await response.json()) as Bench[];
  } catch (e) {
    console.error(e);
    loadingMarker.setIcon(
      divIcon({
        html: getIconSvg(mdiAlert, "#F00").outerHTML,
        className: "errorMarker",
        iconSize: [iconSize, iconSize],
      }),
    );
    setTimeout(() => loadingMarker.remove(), 3000);
    return;
  }
  loadingMarker.remove();
  drawMarkers(data);
  if (searchCircle === null) {
    searchCircle = circle([0, 0], {
      radius,
      color: "blue",
      fill: false,
    }).addTo(map);
  }
  searchCircle.setLatLng(position);
}
function locate() {
  map.locate({ watch: false });
}
map.on("locationfound", (event: LocationEvent) => {
  void onMapClick(event.latlng);
});
