import { mdiMapSearch } from "@mdi/js";
import { MapButton } from "./MapButton";
import { LatLng } from "leaflet";
import { clientInfo } from "./main";

type SearchResponse = {
  place_id: number;
  license: string;
  osm_type: string;
  osm_id: string;
  boundingbox: [number, number, number, number];
  lat: number;
  lon: number;
  display_name: string;
  place_rank: number;
  category: string;
  type: string;
  importance: number;
};

export class SearchBox extends MapButton {
  private open: boolean = false;
  private readonly callback: (latLng: LatLng) => void;
  private readonly _input: HTMLInputElement;
  private readonly _results: HTMLDivElement;
  constructor(callback: (latLng: LatLng) => void) {
    const triggerDummy: Array<() => void> = [];
    super(() => triggerDummy.forEach((item) => item()), mdiMapSearch);
    triggerDummy.push(() => this.toggle());
    this.callback = callback;
    this._results = document.createElement("div");

    this._input = document.createElement("input");
    this._input.style.display = "none";
    this._input.type = "text";
    this._input.placeholder = "Search";
    this._input.onclick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    this._input.onkeydown = async (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      const result = await fetch(
        `${clientInfo.config.nominatimUrl}/search?format=json&q=${encodeURIComponent(this._input.value)}`,
      );
      const data = (await result.json()) as SearchResponse[];
      if (data.length < 1) return alert("No results");
      this._results.innerHTML = "";
      this._results.style.backgroundColor = "white";
      data.forEach((item) => {
        const result = document.createElement("a");
        result.innerText = item.display_name;
        result.href = "#";
        result.style.display = "block";
        result.onclick = (event: MouseEvent) => {
          event.preventDefault();
          event.stopPropagation();
          this.callback(new LatLng(item.lat, item.lon));
        };
        this._results.appendChild(result);
      });
    };
  }

  toggle() {
    this.open = !this.open;
    this._input.style.display = this.open ? "block" : "none";
  }

  onAdd() {
    const button = super.onAdd();

    const container = document.createElement("div");
    container.style.display = "flex";
    container.appendChild(button);
    container.appendChild(this._input);
    container.appendChild(this._results);
    return container;
  }
}
