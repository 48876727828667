import { DomUtil, Control } from "leaflet";

export class MapButton extends Control {
  private readonly _container: HTMLDivElement;
  constructor(callback: (event: MouseEvent) => void, svg: string) {
    super({ position: "topleft" });
    this._container = DomUtil.create(
      "div",
      "leaflet-bar leaflet-control-locate",
    );
    // @ts-expect-error this works fine...
    this._container.width = 24;
    // @ts-expect-error this works fine...
    this._container.height = 24;
    this._container.innerHTML = `<a><svg viewbox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="${svg}"/></svg></a>`;
    this._container.onclick = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      callback(event);
    };
  }

  onAdd() {
    return this._container;
  }
}
